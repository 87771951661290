import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex);

function jsoncatch(a){
    try {
        return JSON.parse(a);
    } catch(e) {
        return {};
    }
}


export default new Vuex.Store({
    state: {
        user: jsoncatch(localStorage.getItem('user')),
        gebot: {
            new: 0,
            act: 0,
            anfrage: 0,
            saleuser: {
                email: ''
            },
            schatz: 0,
            start: 0,
            auktionvalue: 0
        },
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
          }
    },
    actions: {
        login: ({ commit, }, {user }) => {
            commit('SET_USER', user);
             // set auth header
        },
        logout: ({ commit }) => {
            commit('RESET', '');
        }
    },
    getters: {
        isLoggedIn: state => {
            if (state.user){
                if (state.user._id){
                    return true;
                }
                return false;
            }
            return false;
        },

        isAdmin: state => {
            if (state.user){
                if (state.user.isAdmin){
                    return true;
                }
                return false;
            }
            return false;
        },
        getUser: state => {
            return state.user;
        },
    },
    mutations: {
        SET_GEBOT(state, data){
            if (data.anfrage>0){
                state.gebot.anfrage = data.anfrage;
                state.gebot.onlineuser = data.saleuser;
                state.gebot.onlinegebot = data.anfrage;
            } else {
                state.gebot = data;
            }
        },
        SET_USER: (state, user) => {
            state.user = user;
            localStorage.setItem('user',JSON.stringify(user))
        },
        RESET: state => {
          state.user = {};
          localStorage.setItem('user',{});
        },

        SOCKET_ONOPEN (state, event)  {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
        },
        SOCKET_ONCLOSE (state, event)  {
            console.log(event)
            state.socket.isConnected = false
        },
        SOCKET_ONERROR (state, event)  {
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE (state, message)  {
            state.socket.message = message
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
    }
})