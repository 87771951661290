<template>  
  <b-card v-if="isloading">
    <center><b-spinner></b-spinner></center>
  </b-card>
  <b-card v-else>
    <b-card-title>
      <div class="d-flex w-100"><h5 class="mr-auto"></h5><h5 class="">{{actualcatnr}}</h5></div>
    </b-card-title>
    <b-card-text class="mt-2 pt-2 w-100 border-top">
      <div class="d-flex justify-content-between">
        <div class="w-40">
          <div class="font-small">Status:</div>
          <h4 class="text-center text-primary height-1 align-middle d-flex align-items-center">
            <div v-html="auctionatorvalue"></div>
          </h4>
        </div>
        <div class="w-50">
          <div class="text-right w-100 font-small">{{$t('Current bid:')}}</div>
          <div class="height-1 d-flex align-items-center">

            <div class="w-100">
              <h3 class="text-right text-primary mt-3 mb-0">{{(actualprice/1000).toFixed(2)}}  SFr./kg</h3>
              <div class="text-right w-100 font-small">{{actualbuyer}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isloggedin" class="d-flex w-100 justify-content-center">
        <b-button class="w-75 my-4" variant="primary" @click="bieten" :disabled="!buttonenable"><h4 class="m-0 p-0" v-html="buttonvalue"></h4></b-button>
      </div>

      <div v-else>
        <b-card-text>{{$t('You have to log in to participate in the auction.')}}</b-card-text>
      </div>
    </b-card-text>
    <b-modal title="Gewonnen" centered hide-footer ref="win" @close="closemodal">
      <template>
          <div>
              {{$t('Congratulations. You have won.')}}<br><br>
              <!-- <b-form-group label="Wie möchten Sie das Tier transportieren?">
                <b-form-radio v-model="selected" name="some-radios" value="A">Selber transportieren</b-form-radio>
                <b-form-radio v-model="selected" name="some-radios" value="B">Transport durch Vianco</b-form-radio>
                <b-form-radio v-model="selected" name="some-radios" value="C">Ich bin mir noch nicht sicher</b-form-radio>
              </b-form-group>
              <b-button variant="primary" @click="setTransport">Absenden</b-button> -->
          </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  name: 'Auctions-Card',
  data(){
    return {
      selected: 'C',
      isloading: false,
      connection: null,
      auktionvalue: 1
    }
  },
  i18n: {
    messages: {
      de: {
          'Current bid:': 'Aktuelles Gebot:',
          'You have to log in to participate in the auction.':'Sie müssen sich einloggen um an der Auktion teilnehmen zu können.',
          'Congratulations. You have won.': 'Herzlichen Glückwunsch. Sie haben gewonnen.',
          'Auction not<br>yet open': 'Auktion noch<br>nicht offen',
          'Auction<br>open': 'Auktion<br>offen',
          'On the<br>1st': 'Zum<br>1.',
          'On the<br>2nd': 'Zum<br>2.',
          'On the<br>3rd Sold': 'Zum<br>3.Verkauft',
          ' SFr./kg<br>offer': ' SFr./kg<br>bieten',
          'Your offer<br>wins': 'Ihr Gebot<br>gewinnt',
          'No more<br>bid possible': 'Kein Gebot<br>mehr möglich',
          'Your offer<br>is waiting': 'Ihr Angebot<br>wartet',
          'An offer<br>is waiting': 'Ein Angebot<br>wartet',
          
      },
      fr: {
          'Current bid:': 'Enchère actuelle:',
          'You have to log in to participate in the auction.': 'Vous devez vous connecter pour participer à l\'enchère.',
          'Congratulations. You have won.': 'Toutes nos félicitations. Ils ont gagné.',
          'Auction not<br>yet open': 'Enchère pas<br>encore ouverte',
          'Auction<br>open': 'Enchère<br>ouverte',
          'On the<br>1st': 'Le<br>1er',
          'On the<br>2nd': 'Pour<br>le 2e',
          'On the<br>3rd Sold': 'Pour le<br>3ème vendu',
          ' SFr./kg<br>offer': ' SFr.<br>offre',
          'Your offer<br>wins': 'Votre enchère<br>est gagnante',
          'No more<br>bid possible': 'Plus d\'enchère<br>possible',
          'Your offer<br>is waiting': 'Votre offre<br>attend',
          'An offer<br>is waiting': 'Une offre<br>attend',
      }
    }
  },
  props: {
    live: Boolean,
  },
  components: {},
  mounted(){
    this.isloading = true
    if (this.isloggedin){
      if (this.$store.state.socket.isConnected){
        var adduser = {
          event: 'add user',
          user: this.$store.getters.getUser
        }
        this.$socket.sendObj(adduser);
      }
      this.$options.sockets.onopen = (data) => {
        console.log(data);
          var adduser = {
            event: 'add user',
            user: this.$store.getters.getUser
          }
          this.$socket.sendObj(adduser);
        }
      this.$options.sockets.onmessage = (data) => {
          var resp = JSON.parse(data.data);
          console.log(resp);
          if (resp.event == 'actualgebot'){
            this.isloading = false
            var jsongebot = resp.gebot;
            if (this.live){
              if (jsongebot.saleuser != null){
                if (jsongebot.auktionvalue == 4 && jsongebot.saleuser.email == this.$store.getters.getUser.email && jsongebot.saleuser.email != undefined){
                  this.showWin();
                }
              }
            }
            this.auktionvalue = jsongebot.auktionvalue;
            this.$store.commit('SET_GEBOT',jsongebot);
            console.log(jsongebot);
          }
        }
    } else {
      this.isloading = false;
    }
    window.scrollTo(0, 0)
  },
  // sockets: {
  //   actualprice(gebot) {

  //       this.isloading = false
  //       var jsongebot = JSON.parse(gebot);
  //       if (this.live){
  //         if (jsongebot.auktionvalue == 4 && jsongebot.saleuser.email == this.$store.getters.getUser.email && jsongebot.saleuser.email != undefined){
  //           this.showWin();
  //         }
  //       }
  //       this.$store.commit('SET_GEBOT',jsongebot);
  //   }
  // },
  methods: {
    closemodal(){
        this.$refs.win.hide();
    },
    bieten(){
      var gebot = {
        user: this.$store.getters.getUser,
        price: this.actualgebot,
        event: 'set newgebot'
      }
      this.$socket.sendObj(gebot);
      
      // this.$socket.emit('new gebot',this.actualgebot,this.$store.getters.getUser)
    },
    showWin() {
        this.$refs.win.show();
    },
    setTransport(){

    }
  },
  computed:{
    isloggedin(){
      return this.$store.getters.isLoggedIn
    },
    actualcatnr(){
      if (this.live)
      return 'Lauf-Nr. '+this.$store.state.gebot.katnr
        return ''
    },
    actualcow(){
      if (this.live)
      return this.$store.state.gebot.tiername
        return ''
    },
    actualgebot(){
      if (this.live)
        return this.$store.state.gebot.new
      return '0'
    },
    actualprice(){
      if (this.live)
      return this.$store.state.gebot.act
        return '0'
    },
    actualbuyer(){
      if (this.$store.state.gebot.saleuser != null){
        if (this.$store.state.gebot.saleuser.email!='' && this.$store.state.gebot.saleuser.email!=undefined)
        return 'Onlinebieter'
        return 'Bieter im Saal'
      }
      return 'Bieter im Saal'
    },
    auctionatorvalue(){
      if (this.live){
        if (this.$store.state.gebot.auktionvalue === 0) return this.$t('Auction not<br>yet open');
        if (this.$store.state.gebot.auktionvalue === 1) return this.$t('Auction<br>open');
        if (this.$store.state.gebot.auktionvalue === 2) return this.$t('On the<br>1st');
        if (this.$store.state.gebot.auktionvalue === 3) return this.$t('On the<br>2nd');
        if (this.$store.state.gebot.auktionvalue === 4) return this.$t('On the<br>3rd Sold');
        return '';
      }else {
        return this.$t('Auction not<br>yet open');
      }
    },
    buttonenable(){
      if (this.live){
        if (this.auktionvalue === 0) {
          return false;
        } else if (this.auktionvalue === 4){
          return false;
        } else {
          if (this.$store.state.gebot.anfrage > 0){
            return false;
          }
        }
        if (this.$store.state.gebot.saleuser != null){
          if (this.$store.state.gebot.saleuser.email == this.$store.getters.getUser.email){
              return false;
            }
        }
        return true;
      } else {
        return false;
      }
    },
    buttonvalue(){
      if (this.live){

      if (this.$store.state.gebot.auktionvalue === 0) {
        return this.$t('Auction not<br>yet open');
      } else if (this.$store.state.gebot.auktionvalue === 4){
        return this.$t('No more<br>bid possible');
      } else {
        if (this.$store.state.gebot.anfrage > 0){
          // return this.$store.state.gebot.onlineuser.email;
          if (this.$store.state.gebot.onlineuser.email == this.$store.getters.getUser.email){
            return this.$t('Your offer<br>is waiting');
          }
          return this.$t('An offer<br>is waiting');
        }
      }
      
      if (this.$store.state.gebot.saleuser.email == this.$store.getters.getUser.email){
            return this.$t('Your offer<br>wins');
          }
      return (this.$store.state.gebot.new/1000).toFixed(2) + this.$t(' SFr./kg<br>offer');
      }else {
        return this.$t('Auction not<br>yet open');
      }
    },
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  }
  
}
</script>

<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}
.w-40 {
  width: 40%;
}
.height-1{
  height: 100px;
}
.font-small {
  font-size: 8pt;
}
.live {
  color: #2020ff;
}
.isoffline {
  background-color: #e0e0e0;
}
</style>
