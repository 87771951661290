<template>
  <div>
    <top-bar />
    <router-view></router-view>
  </div>
</template>

<script>
import TopBar from './components/Top-Bar.vue'

export default {
  name: 'App',
  components: {
    TopBar,
  }
}
</script>

<style>
</style>
