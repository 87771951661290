<template>
  <div @click="toevent" class="position-relative w-100 cardsize border cursor_pointer p-4 my-2 shadow-sm">
    <div v-if="online" class="position-absolute right-0 bottom-0 translate-middle live mr-3 mb-3"><a :href="'https://viancobackend.swissliveauction.ch/public/'+katalogpdf" target="_blank"><img src="@/assets/catalog.png" width="30" alt=""></a></div>
    <div @click="toevent" :class="isonline">
      <div v-if="live" class="position-absolute right-0 translate-middle live mr-3">Live</div>
      <div>
        <h4>{{title}}</h4>
        <div>Wann: {{datetime}}</div>
        <div>Wo: {{place}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Event-Card',
  props: {
    title: String,
    date: String,
    rassen: Array,
    place: String,
    live: Boolean,
    online: Boolean,
    katalog: Object,
    eventid: String,
  },
  components: {},
  mounted(){
    window.scrollTo(0, 0)
  },
  methods:{
    toevent(){
      if (this.online)
      this.$router.push({path: '/events/' + this.eventid})
    }
  },
  computed:{
    katalogpdf(){
      if (this.katalog !=undefined){
        return this.katalog.key
      } else {
        return ''
      }
    },
    datetime(){
      return new Date(this.date).toLocaleDateString() + ' ' + new Date(this.date).toLocaleTimeString()
    },
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  }
  
}
</script>

<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}
.live {
  color: #2020ff;
}
.isoffline {
  background-color: #e0e0e0;
}
.cursor_pointer {
  cursor: pointer;
}
</style>
