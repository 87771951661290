<template>
  <div class="container">
    <h3>{{$t('Events')}}</h3>
    <div v-if="isloading" class="d-flex w-100 py-4">
      <b-spinner class="m-auto my-4"></b-spinner>
    </div>
    <div v-else>
      <div v-if="filteredevents.length==0" class="d-flex w-100 my-4 p-4">
        <div class="m-auto my-4">{{$t('There are no events planned at the moment')}}</div>
      </div>
        <event-card v-for="event,id in filteredevents" :key="id" :eventid="event._id" :title="event.title" :date="event.date" :live="event.live" :katalog="event.uploadedFile" :online="event.online" :place="event.place"></event-card>
    </div>
  </div>
</template>

<script>
import EventCard from './sub_Event-Wrapper/Event-Card.vue'
import Auction from '@/states/auction.js';

export default {
  name: 'Event-Wrapper',
  data(){
    return{
      allEvents: [],
      isloading: true,
    }
  },
  i18n: {
    messages: {
      de: {
          'Events': 'Veranstaltungen',
          'There are no events planned at the moment':'Es sind im Moment keine Anlässe geplant.'
          
      },
      fr: {
          'Events': 'Événements',
          'There are no events planned at the moment': 'Il n\'y a aucun événement prévu pour le moment'
      }
    }
  },
  components: {EventCard},
  mounted(){
    this.isloading = true
    this.events()
    window.scrollTo(0, 0)
  },
  computed: {
    filteredevents(){
      if (this.$store.getters.isAdmin)
        return this.allEvents;
      const result = this.allEvents.filter(e => e.isTest != true);
      return result;
    }
  },
  methods: {
    async events(){
      try {
        const response = await Auction.events();
        this.allEvents = response
        this.isloading = false
        console.log(this.allEvents)
      } catch(error){
        this.msg = error
        this.isloading = false
        console.log(this.msg)
      }
    }
  }
  
}
</script>

<style style="scss" scoped>
</style>
