<template>
  <div>
    <b-form @submit="login" @submit.stop.prevent v-show="true" v-if="!pwreset">
      <b-form-group id="input-group-1" label="E-Mail Addresse:" label-for="username" description="">
        <b-form-input id="username" v-model="username" type="email" placeholder="E-Mail Adresse" required autocomplete="username"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-5" label="Passwort:" label-for="new-password" >
        <b-form-input id="password" v-model="password" type="password" placeholder="Passwort" required autocomplete="password"></b-form-input>
      </b-form-group>
      <div @click="showpasswordreset">{{$t('forget password?')}}</div>
      <div class="mx-2 my-4">
        <b-button type="submit" class="info-button btn btn-outline-dark rounded-0 w-100">Einloggen</b-button>
      </div>
    </b-form>
    <b-form @submit="passwordreset" @submit.stop.prevent v-show="true" v-if="pwreset">
      {{$t('Reset Password')}}
      <b-form-group id="input-group-1" label="E-Mail Addresse:" label-for="username" description="">
        <b-form-input id="username" v-model="username" type="email" placeholder="E-Mail Adresse" required autocomplete="username"></b-form-input>
      </b-form-group>
      <div class="mx-2 my-4">
        <b-button type="submit" class="info-button btn btn-outline-dark rounded-0 w-100">{{$t('Send Link for reset password')}}</b-button>
      </div>
    </b-form>
    <p class="text-danger" v-if="msg">{{ $t(msg) }}</p>
  </div>
</template>
<script>
import AuthService from '@/states/AuthService.js';

export default {
  data() {
    return {
      username: '',
      password: '',
      msg: '',
      pwreset: false,
    };
  },
  i18n: {
    messages: {
      de: {
          'forget password?': 'Passwort vergessen?',
          'Invalid Credentials': 'Falscher Benutzername oder Passwort',
          'Send Link for reset password': 'Link zum Passwort zurücksetzen senden',
          'Reset password link sent on your email id.': 'Link zum Passwort zurücksetzten wurde versendet',
          
      },
      fr: {
          'forget password?': 'Mot de passe oublié?',
          'Invalid Credentials': 'Nom d\'utilisateur ou mot de passe erroné',
          'Send Link for reset password': 'Envoyer le lien pour réinitialiser le mot de passe',
          'Reset password link sent on your email id.': 'Le lien pour réinitialiser le mot de passe a été envoyé',
          
      }
    }
  },
  methods: {
    showpasswordreset(event){
      event.preventDefault()
      this.pwreset = true;
    },
    async passwordreset(){
      try {
        const response = await AuthService.passwordreset(this.username);
        this.msg = response.msg
        console.log(this.msg)
        this.pwreset = false;
      } catch(error){
        this.msg = error
        console.log(this.msg)
      }
    },
    async login() {
      try {
        this.msg = '';
        var credentials = {
          username: this.username,
          password: this.password
        };
        const response = await AuthService.login(credentials);
        
        if (response.user){
          const user = response.user;
          this.$store.dispatch('login', { user });
          this.$emit('login');
          var adduser = {
          event: 'add user',
          user: this.$store.getters.getUser
        }
        this.$socket.sendObj(adduser);
        }
      } catch (error) {
        this.msg = error.response.data.message;
      }
    }
  }
};
</script>