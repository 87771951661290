<template>
    <b-navbar variant="primary" :type="navbartheme">
        <b-navbar-brand>
            <div @click="goHome"><img :src="logo" width="100" ></div>
        </b-navbar-brand>
        <b-navbar-nav class="right">
        <b-nav-item v-if="!isloggedin" @click="showLogin">
            Login
        </b-nav-item>
        <b-nav-item v-else @click="showUser">
            {{ username}}
        </b-nav-item>
        <b-nav-item v-if="isAdmin" @click="showAdmin" >
            Admin
        </b-nav-item>
        </b-navbar-nav>
        <b-modal title="Login" centered hide-footer ref="login" @close="closemodal">
            <b-tabs content-class="mt-3" fill v-if="!isloggedin">
                <b-tab title="Login"><login @login="close" /></b-tab>
                <b-tab title="Registrieren"><sign-up @sign="close" /></b-tab>
            </b-tabs>
            <template v-else>
                <div class="d-flex flex-row-reverse">
                    <button class="mb-3 mt-0" @click="logout">Abmelden</button>
                </div>
            </template>
        </b-modal>
    </b-navbar>
</template>

<script>
import Login from '../modules/login.vue';
import SignUp from '../modules/Sign-Up.vue';

export default {
  components: { SignUp, Login,},
  name: 'Top-Bar',
  i18n: {
    messages: {
      de: {
        'become a provider': 'Partner werden',
        'Provider': 'Partner',
        'portal': 'Portal',
        'male': 'männlich',
        'female': 'weiblich',
        'edit': 'bearbeiten',
        'email': 'E-Mail',
        'Enter email': 'E-Mail eingeben',
        'first name': 'Vorname',
        'Enter name': 'Name eingeben',
        'last name': 'Nachname',
        'Enter last name': 'Nachname eingeben',
        'street': 'Adresse',
        'Enter street': 'Strasse eingeben',
        'zip-code': 'PLZ',
        'Enter ZIP-Code': 'PLZ eingeben',
        'place': 'Ort',
        'Enter Place': 'Ort eingeben',
        'day of birth': 'Geburtstag',
        'save': 'Sichern',
        'cancel': 'Abbrechen',
        'Orders': 'Buchungen',
        'You don\'t have orders': 'Du hast keine Buchungen',
        'Change password': 'Passwort ändern',
        'password': 'Passwort',
        'Enter password': 'Passwort eingeben',
        'confirm password': 'Passwort bestätigen'
      }
    }
  },
  data(){
      return {
          useredit: false,
          form: {
          },
          reservations: [],
          passwordedit: false,
          password: {
              email: '',
              token: '',
              password: '',
              confirmed_password: '',
          }
      }
  },
  computed: {
      navbartheme(){
          if (process.env.VUE_APP_SITE == 'Mutterkuh')
            return 'light'
            return 'dark'
      },
      logo(){
          if (process.env.VUE_APP_SITE == 'Mutterkuh')
            return require('@/assets/mutterkuhlogo.png')
            return require('@/assets/logo.png')
      },
      isAdmin(){
        return this.$store.getters.isAdmin
      },
      isloggedin(){
        return this.$store.getters.isLoggedIn
      },
      username(){
        if (this.$store.getters.isLoggedIn) {
            return this.$store.getters.getUser.vorname + ' ' + this.$store.getters.getUser.nachname
        }
        return ''
      },
      userimage(){
          if (this.user.image === null)
            return require('@/assets/img/no-pic.png')
            return this.user.image;
      },
      user(){
        //   console.log(this.$store.getters.getUser)
        return this.$store.getters.getUser
      },
      isProvider(){
        // return (this.$store.getters.getUser.user_type == 'provider')
        return false
      },
  },
  methods:{
      closemodal(){
          this.useredit = false;
      },
      edituser(){
          this.useredit = true;
          this.form = this.$store.getters.getUser;
      },
      onEdit(){
          this.updateprofile()
      },
      onPasswordEdit(){
          this.updatepassword()
      },
      partner(){
          this.$router.push({path: '/#partner'})
      },
      close(){
          this.$refs.login.hide();
      },
      goHome() {
        this.$router.push({ path: '/'}).catch(()=>{});
      },
      showLogin() {
          this.$refs.login.show();
      },
      showAdmin() {
          this.$router.push({ path: '/settings'}).catch(()=>{});
      },
      showUser() {
          this.$refs.login.show();
      },
      logout(){
          this.$store.dispatch('logout');
          this.$refs.login.hide();
      }
  }
}
</script>
<style scoped>
.right{
    position: absolute;
    right: 5px;
    top: 10px;
}
</style>