import Vue from 'vue'
import VueRouter from 'vue-router';
import store from '../states/store';

Vue.use(VueRouter);

import EventWrapper from '@/main/Event-Wrapper'
import Event from '@/main/Event'
import settings from '@/modules/settings'

const routes = [
    {
        path: '/',
        component: EventWrapper
    },
    {
        path: '/events/:eventID',
        component: Event,
    },
    {
        path: '/settings',
        beforeEnter: checkAdminRights,
        component: settings,
    },
]

function checkAdminRights(to, from, next) {
  // check if the user is admin
  console.log(store.getters.isLoggedIn)
  if(store.getters.isAdmin) {
      next();       
  } else {
      next({ path: '/'});
  }
}

const scrollBehavior = function (to, from, savedPosition) {
    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition
    } else {
      const position = {}
  
      // scroll to anchor by returning the selector
      if (to.hash) {
        position.selector = to.hash
  
  
        // bypass #1number check
        if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
          return position
        }
  
        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        return false
      }
  
      return new Promise(resolve => {
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some(m => m.meta.scrollToTop)) {
          // coords will be used if no selector is provided,
          // or if the selector didn't match any element.
          position.x = 0
          position.y = 0
        }
  
        // wait for the out transition to complete (if necessary)
        this.app.$root.$once('triggerScroll', () => {
          // if the resolved position is falsy or an empty object,
          // will retain current scroll position.
          resolve(position)
        })
      })
    }
  }

export const router = new VueRouter({
    routes,
    scrollBehavior,
})