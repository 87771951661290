<template>
    <b-container>
      <b-row>
        <b-col sm="3">
          <b-list-group>
            <b-list-group-item button @click="loaddata('events')">Events</b-list-group-item>
            <b-list-group-item button @click="loaddata('cows')">Tiere</b-list-group-item>
            <b-list-group-item button @click="loaddata('users')">Users</b-list-group-item>
            <b-list-group-item button @click="loaddata('history')">History</b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col sm="9" >
          <template>
            <div class="d-flex justify-content-end">
                <div class="mx-3">
                  <b-form-group v-for="(filter,key) in globalfilters" :key="key" :label="filter.label">
                    <b-form-select v-model="globalfilters[key].value" v-if="filter.type == 'select'" :options="filter.options" @change="changefilter(key, filter)"></b-form-select>
                    <b-form-checkbox  v-else-if="filter.type == 'Boolean'" value="true" unchecked-value="false"></b-form-checkbox>
                    <b-form-input  v-else :type="filter.type"></b-form-input>
                  </b-form-group>
                </div>
                <div>
                  <b-button-group class="m-3">
                    <b-button v-for="(action, id) in globalactions" :key="id" size="sm" @click="callglobalaction(action)">
                          {{action.label}}
                    </b-button>
                  </b-button-group>
                </div>
            </div>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="settings-table"></b-pagination>
            <b-container fluid class="overflow vh-85">
              
              <!-- Main tablepassport.s element -->
              <b-table
                id="settings-table"
                :items="myProvider"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
              >

                <template #cell(actions)="row">
                  <b-button-group>
                    <b-button v-for="action, id in actions" :key="id" size="sm" @click="callaction(action, row.item)">
                      {{action.label}}
                    </b-button>
                  </b-button-group>
                  <!-- <b-button size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
                  </b-button> -->
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                  </b-card>
                </template>
              </b-table>

              <!-- Info modal -->
              <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hidden="resetInfoModal" @ok.prevent="handleok">
                <b-button
                      :variant="primary"
                      size="sm"
                      class="float-right"
                      @click="print"
                    >
                      Drucken
                    </b-button>
                <b-form id="printinfo">

                  <b-form-group  v-for="(field,key) in infoModal.fields" :key="key" :label="field.label">
                    <b-form-select v-model="infoModal.value[field.key]" v-if="field.type == 'select'" :options="field.options"></b-form-select>
                    <b-form-text v-else-if="field.type == 'info'" >{{infoModal.value[field.key]}}</b-form-text>
                    <VueEditor v-else-if="field.type == 'html'" v-model="infoModal.value[field.key]"/>
                    <b-form-checkbox v-model="infoModal.value[field.key]" v-else-if="field.type == 'Boolean'" value="true" unchecked-value="false"></b-form-checkbox>
                    <b-form-input v-model="infoModal.value[field.key]" v-else :type="field.type"></b-form-input>
                  </b-form-group>
                </b-form>
                <div class="text-danger">{{infoModal.msg}}</div>
                    
              </b-modal> 
            </b-container>
          </template>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import AuthService from '@/states/AuthService.js';
import { VueEditor } from "vue2-editor";

export default {
  name: 'googlemap',
  props: {
    
  },
  methods: {
    loaddata(endpoint){
      this.last = endpoint
      this.endpoint = endpoint
      this.$root.$emit('bv::refresh::table', 'settings-table')
    },
    print(){
      this.$htmlToPaper('printinfo')
    },
    changefilter(key,value){
      console.log('changefilter'+key)
      this.activefilter[key]=value.value
      this.filter = value.key+'='+value.value;
      this.currentPage = 1;
    },
    async handleok(){
      try {
        var username = this.$store.getters.getUser.email;
        var password = this.$store.getters.getUser.password;
        var credentials = btoa(username + ':' + password);
        var basicAuth = 'Basic ' + credentials;
        const response = await AuthService.postevent(this.infoModal.endpoint, this.infoModal.value,basicAuth);
        console.log(response)
        console.log(this.infoModal.value)
        this.$root.$emit('bv::hide::modal', this.infoModal.id, )
        this.loaddata(this.last);
      } catch(error){
        this.infoModal.msg=error;
      }
    },
    resetInfoModal(){
      this.infoModal.title = '';
      this.infoModal.content = '';
      this.infoModal.fields = [];
      this.infoModal.value = {};
      this.infoModal.msg = '';
    },
    async customcall(action){
        if (action.http == "get"){
          const response = await AuthService.getevent(action.endpoint);
          console.log(response);
        }
        if (action.http == "socket"){
          var data = {
            event: action.endpoint,
            value: action.index
          }
          this.$socket.sendObj(data);

        }
        this.loaddata(this.last);
      },
    async customrowcall(action, id){
        if (action.http == "get"){
          const response = await AuthService.getevent(action.endpoint + id);
          console.log('test'+response);
        }
        if (action.http == "socket"){
          var data = {
            event: action.endpoint,
            value: id
          }
          this.$socket.sendObj(data);

        }
        this.loaddata(this.last);
      },
      callaction(action, row){
        if (action.type == "edit"){
          this.infoModal.title = action.label
          this.infoModal.fields = action.fields
          this.infoModal.endpoint = action.endpoint + '/' + row[action.index]
          this.infoModal.value = {}
          action.fields.forEach(element => {
            this.infoModal.value[element.key]=row[element.key]
          });
          console.log(this.infoModal)
          this.$root.$emit('bv::show::modal', this.infoModal.id, )
        } else if (action.type == "info"){
          this.infoModal.title = action.label
          this.infoModal.fields = action.fields
          this.infoModal.endpoint = action.endpoint + '/' + row[action.index]
          this.infoModal.value = row
          this.$root.$emit('bv::show::modal', this.infoModal.id, )
        } else if (action.type == "custom"){
          this.customrowcall(action, row[action.index]);
        }
      },
      callglobalaction(action){
        if (action.type == "edit"){
          console.log('edit');
        } else if (action.type == "add"){
          this.infoModal.title = action.label
          this.infoModal.fields = action.fields
          this.infoModal.endpoint = action.endpoint
          this.$root.$emit('bv::show::modal', this.infoModal.id, )
        } else if (action.type == "custom"){
          this.customcall(action);
        }
      },
      async myProvider(ctx){
        console.log(ctx)
        try {
          var username = this.$store.getters.getUser.email;
          var password = this.$store.getters.getUser.password;
          var credentials = btoa(username + ':' + password);
          var basicAuth = 'Basic ' + credentials;
          const response = await AuthService.loaddata(this.endpoint+'?page='+ctx.currentPage+'&size='+ctx.perPage+'&'+ctx.filter+'&sort='+ctx.sortBy+'&sortDesc='+ctx.sortDesc, basicAuth);
          this.fields = response.fields;
          this.actions = response.actions;
          this.globalfilters = response.filters;
          this.globalactions = response.globalactions;
          console.log(response)
          this.totalRows = response.result.pagination.count;
          for (let i in this.globalfilters){
            this.globalfilters[i].value = this.activefilter[i]
          }
          return response.result.result;
          
        } catch (error){
          this.msg = error.response
        }
      },
  },
  components: {VueEditor},
  computed: {
    primary(){
        if (process.env.VUE_APP_SITE == 'Mutterkuh')
          return 'mutterkuh'
          return 'primary'
    },
    sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    
  },
  mounted(){
  },
  
  data () {
    return {
      last: '',
      infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
          fields: [],
          value: {},
          endpoint: '',
          msg: ''
        },
      items: [],
      fields: [],
      actions: [],
      filters: [],
      globalactions: [],
      globalfilters: [],
      endpoint: 'events',
      totalRows: 100,
        currentPage: 1,
        perPage: 20,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        activefilter: []
        
    }
  }
}
</script>

<style style="scss" scoped>
.overflow {
  overflow-x: auto;
}
.vh-85 {
  height: 75vh;
}
</style>
