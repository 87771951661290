import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {en} from './en.js';
import {de} from './de.js';
var supportedLanguages = ['en', 'de'];

Vue.use(VueI18n);

var lang = null;
try {
  lang = localStorage.getGlobalItem('language');
} catch (e) {
  console.log(e);
}

if (lang === null) {
  lang = detectLanguage();
  console.log('found language', lang);
} else {
  console.log('found stored language', lang);
}

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'de',
  messages: {en: en, de: removeEmptyTranslations(de)},
  silentTranslationWarn: true, // To find missing translations turn this off.
  numberFormats: {
    'en': {
      default: {
        style: 'decimal', maximumFractionDigits: 3
      },
      integer: {
        style: 'decimal', maximumFractionDigits: 0
      },
      temperature: {
        style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1
      }
    },
    'de': {
      default: {
        style: 'decimal', maximumFractionDigits: 3
      },
      integer: {
        style: 'decimal', maximumFractionDigits: 0
      },
      temperature: {
        style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1
      }
    }
  }
});

export default i18n;

function detectLanguage() {
  var fallback = 'de';
  var lang = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage);
  lang = String(lang).toLowerCase();
  for (var i = 0; i < supportedLanguages.length; i++) {
    if (lang === supportedLanguages[i].toLowerCase()) {
      return supportedLanguages[i];
    }
  }
  for (var j = 0; j < supportedLanguages.length; j++) {
    if (lang.startsWith(supportedLanguages[j])) {
      return supportedLanguages[j];
    }
  }
  return fallback;
}

function removeEmptyTranslations(translations) {
  const removeEmpty = (obj) => {
    var filtered = Object.keys(obj).filter((k) => {
      return obj[k] !== null && obj[k] !== undefined && obj[k] !== ''; // Remove null, undefined and empty string.
    });
    return filtered.reduce((newObj, k) => {
      if (typeof obj[k] === 'object') {
        return Object.assign(newObj, {[k]: removeEmpty(obj[k])}); // Recurse.
      } else {
        return Object.assign(newObj, {[k]: obj[k]}); // Copy value.
      }
    }, {});
  };
  return removeEmpty(translations);
}
