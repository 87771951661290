<template>  
  <b-card v-if="isloading">
    <center><b-spinner></b-spinner></center>
  </b-card>
  <b-card v-else>
    <b-card-title>
      <div class="d-flex w-100">
        <b-row>
          <b-col sm="10" class="d-flex">
            <div class="mr-auto">
              <h3>{{cow.katalognr}}</h3>
              <h5>{{cow.Rasse}}</h5>
            </div>
            <div class="mr-auto">
              <h3 >{{cow.tiername}}</h3>
              <h6>{{cow.tvdnr}}</h6>
              <h6>né.: {{cow.birthdate}}</h6>
              <h6>{{cow.Tier_BioStandort}}</h6>
              <h6>{{cow.Abmeldung}}</h6>
            </div>
          </b-col>
          <b-col sm="2">
            <h5>
              {{cow.GenMarkerHorn}} {{cow.GenMarkerDoppellender}}
            </h5>
            <h5>{{cow.Elite}}</h5>
          </b-col>
        </b-row>
      </div>
    </b-card-title>
      <b-card-text class="mt-2 pt-2 w-100 border-top">
        <b-row class="w-100 m-0">
          <b-col class="pl-0 py-1">V/P: {{cow.V_Langname}}</b-col>
          <b-col class="py-1">MV/PM: {{cow.MV_Langname}}</b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col class="pl-0 py-1">M/M: {{cow.M_Langname}}</b-col>
          <b-col class="py-1">M: ({{cow.M_LB}})</b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1 border text-center font-weight-bold">{{cow.Klassierung}} - {{cow.Synthese}}</b-col>
          <b-col sm class="p-1 border"><b-row><b-col cols="5">Gr. / Taille</b-col><b-col>[{{cow.Ziffer_W_H}}]</b-col><b-col class="text-right pr-5">{{cow.Groesse}} cm</b-col></b-row></b-col>
          <b-col sm class="p-1 border"><b-row><b-col cols="5">IBB</b-col><b-col></b-col><b-col class="text-right pr-5">{{cow.IBB}}</b-col></b-row></b-col>
          </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1 border"><b-row><b-col>Fo / Fo</b-col><b-col class="text-right pr-5">{{cow.Format}}</b-col></b-row></b-col>
          <b-col sm class="p-1 border"><b-row><b-col>Gew./Poids</b-col><b-col class="text-right pr-5">{{cow.Gewicht}} kg</b-col></b-row></b-col>
          <b-col sm class="p-1 border"><b-row><b-col cols="5">GA<sub>b</sub>/DV<sub>b</sub> </b-col><b-col>[{{cow.LabelGABeef}}]</b-col><b-col class="text-right pr-5">{{cow.GABeef}}</b-col></b-row></b-col>
          </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1 border"><b-row><b-col>Be / Mu</b-col><b-col class="text-right pr-5">{{cow.Bemuskelung}}</b-col></b-row></b-col>
          <b-col sm class="p-1 border"><b-row><b-col>AG/PS205</b-col><b-col class="text-right pr-5">{{cow.AG205}} kg</b-col></b-row></b-col>
          <b-col sm class="p-1 border"><b-row><b-col cols="5">AGD/PSD</b-col><b-col>[{{cow.LabelAGD}}]</b-col><b-col class="text-right pr-5">{{cow.AGD}}</b-col></b-row></b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1 border"><b-row><b-col>Fu / Me</b-col><b-col class="text-right pr-5">{{cow.Fundament}}</b-col></b-row></b-col>
          <b-col sm class="p-1 border"><b-row><b-col>TZ/GJ205</b-col><b-col class="text-right pr-5">{{cow.TZ205}} g</b-col></b-row></b-col>
          <b-col sm class="p-1 border"><b-row><b-col cols="5">SG<sub>MT</sub>/PM<sub>MT</sub></b-col><b-col>[{{cow.ZWLabelMT}}]</b-col><b-col class="text-right pr-5">{{cow.SGMT}}</b-col></b-row></b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1 border"><b-row><b-col>LB / DL</b-col><b-col class="text-right pr-5">{{cow.Datum_letzte_LB}}</b-col></b-row></b-col>
          <b-col class="p-1"></b-col>
          <b-col sm class="p-1 border"><b-row><b-col cols="5">F<sub>MT</sub>/C<sub>MT</sub></b-col><b-col>[{{cow.ZWLabelMT}}]</b-col><b-col class="text-right pr-5">{{cow.FMT}}</b-col></b-row></b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1"></b-col>
          <b-col class="p-1"></b-col>
          <b-col sm class="p-1 border"><b-row><b-col cols="5">FET<sub>MT</sub>/GRA<sub>MT</sub></b-col><b-col>[{{cow.ZWLabelMT}}]</b-col><b-col class="text-right pr-5">{{cow.FettMT}}</b-col></b-row></b-col>
        </b-row>
        <b-row class="w-100 m-0 mt-3">
          <b-col sm class="p-1">Züchter<br>Eleveur</b-col>
          <b-col sm class="p-1 font-weight-bold"><h4>{{cow.Zuechter}}, {{cow.ZuechterOrt}}</h4></b-col>
          </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1">Besitzer<br>Propriétaire</b-col>
          <b-col sm class="p-1 font-weight-bold"><h4>{{cow.Besitzer}}, {{cow.BesitzerOrt}}</h4></b-col>
        </b-row>
      </b-card-text>
  </b-card>
</template>

<script>

import Auction from '@/states/auction.js';
export default {
  name: 'Cow-Card',
  data(){
    return {
      cow: {},
      isloading: true,
    }
  },
  props: {
    id:{
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  mounted(){
    if (this.isActive){
      this.Cow(this.id)
    }
    window.scrollTo(0, 0)
  },
  methods:{
    // vorbieten(){
    //   this.$socket.emit('new vorgebot',this.id,this.newprice,this.$store.getters.getUser)
    // },
    async Cow(id){
      try {
        const response = await Auction.cow(id);
        this.cow = response
        this.isloading = false
      } catch(error){
        this.msg = error
        // console.log(this.msg)
      }
    }
  },
  computed:{
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  },
  watch: {
    isActive: function (val) {
      if (val){

        // this.$socket.emit('showdata',this.id)
        // this.sockets.subscribe('actualvorgebot', (data) => {
        //   if (data[0]==this.id){
        //     var jsondata = JSON.parse(data[1])
        //     if (jsondata.auktionvalue == 0) {
        //       this.newprice = jsondata.new
        //       this.actprice = jsondata.act
        //       this.saleuser = jsondata.saleuser
        //       this.isOpen = true
        //     } else {
        //       this.isOpen = false
        //     }
        //   }
        // });
        // this.sockets.subscribe('actualprice', (data) => {
        //   var jsondata = JSON.parse(data)
        //   if (jsondata.cow==this.id){
        //     if (jsondata.auktionvalue == 0) {
        //       this.newprice = jsondata.new
        //       this.actprice = jsondata.act
        //       this.saleuser = jsondata.saleuser
        //       this.isOpen = true
        //     } else {
        //       this.isOpen = false
        //     }
        //   }
        // });
        this.Cow(this.id)
      } else {
        // this.sockets.unsubscribe('actualvorgebot')
          this.newprice = 0
          this.actprice = 0
          this.isOpen = false
      }
    }
  }
  
}
</script>

<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}
.live {
  color: #2020ff;
}
.isoffline {
  background-color: #e0e0e0;
}
</style>
