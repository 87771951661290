<template>  
  <b-card v-if="isloading">
    <center><b-spinner></b-spinner></center>
  </b-card>
  <b-card v-else>
    <b-card-title>
      <div class="d-flex w-100"><h3 class="mr-auto">{{cow.tiername}}</h3><h3 class="">Kat.-Nr. {{cow.katalognr}}</h3></div>
    </b-card-title>
    <b-card-sub-title>
      <div class="d-flex w-100">
        <div class="mr-auto">{{cow.tvdnr}}</div>
        <div class="">geb.: {{cow.birthdate}}</div>
      </div>
    </b-card-sub-title>
      <b-card-text class="mt-2 pt-2 w-100 border-top">
      <b-row class="w-100 m-0">
        <b-col class="pl-0 py-1">gekalbt:</b-col>
        <b-col class="py-1">{{cow.gekalbt}}</b-col>
      </b-row>
      <b-row class="w-100 m-0">
        <b-col class="pl-0 py-1">9 Mt. am :</b-col>
        <b-col class="py-1">{{cow.ninemonth}}</b-col>
      </b-row>
      <b-row class="w-100 m-0">
        <b-col class="pl-0 py-1">Tagesmilch :</b-col>
        <b-col class="py-1">{{cow.daymilk}}</b-col>
      </b-row>
      <b-row class="w-100 m-0">
        <b-col class="pl-0 py-1">Bemerkungen :</b-col>
        <b-col class="py-1">{{cow.notes}}</b-col>
      </b-row>
      <b-row class="w-100 m-0">
        <div class="mx-2"><b-icon-check-square v-if="cow.laufstall" /><b-icon-square v-else /></div>Laufstall
        <div class="mx-2"><b-icon-check-square v-if="cow.silo" /><b-icon-square v-else /></div>Silo
        <div class="mx-2"><b-icon-check-square v-if="cow.bio" /><b-icon-square v-else /></div>Bio-Knospe
      </b-row>
      <b-row class="w-100 m-0" v-if="cow.Nr!='0'">
        <div>{{cow.Nr}}</div>
      </b-row>
      <b-row class="w-100 m-0" v-if="cow.nrtwo!='0'">
        <div>{{cow.nrtwo}}</div>
      </b-row>
      </b-card-text>
  </b-card>
</template>

<script>
import { BIconSquare, BIconCheckSquare } from 'bootstrap-vue'
import Auction from '@/states/auction.js';
export default {
  name: 'Cow-Card',
  data(){
    return {
      isloading: true,
      cow: {},
      newprice: 0,
      actprice: 0,
      saleuser: {
        email: ''
      },
      isOpen: false,
    }
  },
  props: {
    id:{
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: {BIconSquare, BIconCheckSquare},
  computed:{
    buttonenable(){
        if (this.saleuser.email == this.$store.getters.getUser.email){
          return true
        }
        return false
    },
    buttonvalue(){
      if (this.saleuser.email == this.$store.getters.getUser.email){
        return 'Sie gewinnen'
      }
      return this.newprice + '.- SFr.<br>bieten'
    },
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  },
  mounted(){
    if (this.isActive){
      this.Cow(this.id)
    }
    window.scrollTo(0, 0)
  },
  methods:{
    // vorbieten(){
    //   this.$socket.emit('new vorgebot',this.id,this.newprice,this.$store.getters.getUser)
    // },
    async Cow(id){
      try {
        const response = await Auction.cow(id);
        this.cow = response
        this.isloading = false
      } catch(error){
        this.msg = error
        // console.log(this.msg)
      }
    }
  },
  watch: {
    isActive: function (val) {
      if (val){

        // this.$socket.emit('showdata',this.id)
        // this.sockets.subscribe('actualvorgebot', (data) => {
        //   if (data[0]==this.id){
        //     var jsondata = JSON.parse(data[1])
        //     if (jsondata.auktionvalue == 0) {
        //       this.newprice = jsondata.new
        //       this.actprice = jsondata.act
        //       this.saleuser = jsondata.saleuser
        //       this.isOpen = true
        //     } else {
        //       this.isOpen = false
        //     }
        //   }
        // });
        // this.sockets.subscribe('actualprice', (data) => {
        //   var jsondata = JSON.parse(data)
        //   if (jsondata.cow==this.id){
        //     if (jsondata.auktionvalue == 0) {
        //       this.newprice = jsondata.new
        //       this.actprice = jsondata.act
        //       this.saleuser = jsondata.saleuser
        //       this.isOpen = true
        //     } else {
        //       this.isOpen = false
        //     }
        //   }
        // });
        this.Cow(this.id)
      } else {
        // this.sockets.unsubscribe('actualvorgebot')
          this.newprice = 0
          this.actprice = 0
          this.isOpen = false
      }
    }
  }
  
}
</script>

<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}
.font-small{
  font-size: 8pt;
}
.live {
  color: #2020ff;
}
.isoffline {
  background-color: #e0e0e0;
}
</style>
