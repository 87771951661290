<template>  
  <b-card v-if="isloading">
    <center><b-spinner></b-spinner></center>
  </b-card>
  <b-card v-else>
    <b-card-title>
      <div class="d-flex w-100"><h3 class="mr-auto">{{cow.tiername}}</h3><h3 class="">Kat.-Nr. {{cow.katalognr}}</h3></div>
    </b-card-title>
    <b-card-sub-title>
      <div class="d-flex w-100">
        <div class="mr-auto">{{cow.tvdnr}}</div>
        <div class="">geb.: {{cow.birthdate}}</div>
      </div>
      <div v-if="isOpen" class="my-3 d-flex justify-content-center">
        <div class="mx-4 d-flex justify-content-center align-items-center"><div><div class="font-small">Akt. Gebot:</div>{{actprice}}.- SFr.</div></div>
        <b-button @click="vorbieten" :disabled="buttonenable" variant="primary" ref="bieten" v-html="buttonvalue"></b-button>
      </div>
    </b-card-sub-title>
      <b-card-text class="mt-2 pt-2 w-100 border-top">
        <div class="d-flex my-4 row">
          <div class="col-sm-6">
            <vueper-slides :slideRatio="0.8" class="frame" v-if="cow.pic.length>0" slide-image-inside slideContentOutside='bottom'>
              <vueper-slide v-for="(slide, i) in bilder" :key="i" :image="image(i)" >
              <template #content>
                <div style="color: black; text-align: center; font-size: 18px; font-weight: 700;">
                  <span>{{ slide }}</span>
                </div>
              </template>
              </vueper-slide>
            </vueper-slides>
          </div>
          <div class="col-sm-6 p-4" v-html="cow.htmlcontent"></div>
          </div>
      </b-card-text>
  </b-card>
</template>

<script>
import Auction from '@/states/auction.js';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: 'Cow-Card',
  data(){
    return {
      isloading: true,
      cow: {},
      newprice: 0,
      actprice: 0,
      saleuser: {
        email: ''
      },
      isOpen: false,
      slide: 0,
      sliding: null
    }
  },
  props: {
    id:{
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: {VueperSlides, VueperSlide },
  computed:{
    bilder(){
      return this.cow.pic.filter(pic => pic != "")
    },
    backend(){
      return 'https://'+process.env.VUE_APP_BACKEND
    },
    buttonenable(){
        if (this.saleuser.email == this.$store.getters.getUser.email){
          return true
        }
        return false
    },
    buttonvalue(){
      if (this.saleuser.email == this.$store.getters.getUser.email){
        return 'Sie gewinnen'
      }
      return this.newprice + '.- SFr.<br>bieten'
    },
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  },
  mounted(){
    if (this.isActive){
      this.Cow(this.id)
    }
    window.scrollTo(0, 0)
  },
  methods:{
    video(frame){
      if (frame.Video){
        return frame.src
      }
      return ''
    },
    image(frame, type){
      if (type!='Video'){
        return this.backend + 'files/' + this.cow.katalognr + '/'+ (frame + 1) + '.jpg'
      }
      return ''
    },
    // vorbieten(){
    //   this.$socket.emit('new vorgebot',this.id,this.newprice,this.$store.getters.getUser)
    // },
    async Cow(id){
      try {
        const response = await Auction.cow(id);
        this.cow = response
        this.isloading = false
      } catch(error){
        this.msg = error
        // console.log(this.msg)
      }
    }
  },
  watch: {
    isActive: function (val) {
      if (val){

        // this.$socket.emit('showdata',this.id)
        // this.sockets.subscribe('actualvorgebot', (data) => {
          // if (data[0]==this.id){
            // var jsondata = JSON.parse(data[1])
            // if (jsondata.auktionvalue == 0) {
              // this.newprice = jsondata.new
              // this.actprice = jsondata.act
              // this.saleuser = jsondata.saleuser
              // this.isOpen = true
            // } else {
              // this.isOpen = false
            // }
          // }
        // });
        // this.sockets.subscribe('actualprice', (data) => {
          // var jsondata = JSON.parse(data)
          // if (jsondata.cow==this.id){
            // if (jsondata.auktionvalue == 0) {
              // this.newprice = jsondata.new
              // this.actprice = jsondata.act
              // this.saleuser = jsondata.saleuser
              // this.isOpen = true
            // } else {
              // this.isOpen = false
            // }
          // }
        // });
        this.Cow(this.id)
      } else {
        // this.sockets.unsubscribe('actualvorgebot')
          this.newprice = 0
          this.actprice = 0
          this.isOpen = false
      }
    }
  }
  
}
</script>
<style style="scss">
.vueperslide__image {
  background-size: contain !important;
}
.vueperslides__arrow {
  color: rgb(50,50,50) !important;
}
</style>
<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}



.font-small{
  font-size: 8pt;
}
.live {
  color: #2020ff;
}
.frame {
  height: 300px;
}
.vueperslides__inner {
  height: 300px;
}
.isoffline {
  background-color: #e0e0e0;
}
.framesize {
  width: 50%;
  height: 300px;
}
</style>
