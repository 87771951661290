<template>  
  <div>
  </div>
</template>

<script>
export default {
  name: 'Cow-Card',
  props: {
    title: String,
    date: String,
    rassen: Array,
    place: String,
    live: Boolean,
    online: Boolean,
  },
  components: {},
  mounted(){
    window.scrollTo(0, 0)
  },
  computed:{
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  }
  
}
</script>

<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}
.live {
  color: #2020ff;
}
.isoffline {
  background-color: #e0e0e0;
}
</style>
