import axios from 'axios';


const url = 'https://'+process.env.VUE_APP_BACKEND;

export default {
  katalog(event){
    return axios
      .get(url + 'katalog/'+event,)
      .then(response => response.data)
  },
  event(event){
    return axios
      .get(url + 'event/'+event,)
      .then(response => response.data)
  },
  events(){
    return axios
      .get(url + 'events',)
      .then(response => response.data)
  },
  cow(id){
    return axios
      .get(url + 'cow/' + id,)
      .then(response => response.data)
  },
};