<template>
  <div>
      
    <b-form @submit="signUp" @submit.stop.prevent v-show="true">
      <b-form-group id="input-group-1" :label="$t('Email Addresse:')" label-for="input-1" description="">
        <b-form-input id="input-1" v-model="credentials.email" type="email" placeholder="E-Mail Adresse" required autocomplete="username"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-5" :label="$t('Passwort:')" label-for="new-password" description="Mindest 8 Zeichen inkl. Zahl oder Sonderzeichen">
        <b-form-input id="new-password" v-model="credentials.password" :state="ispassword" type="password" placeholder="Passwort" required autocomplete="new-password"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-6" :label="$t('Passwort wiederholen')" label-for="password_repeat" description="">
        <b-form-input id="password_repeat" v-model="credentials.password_confirmation" :state="issamepassword" type="password" placeholder="Passwort wiederholen" required autocomplete="new-password"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-2" :label="$t('First Name')" label-for="fname" description="">
        <b-form-input id="fname" v-model="credentials.first_name"  placeholder="Vorname" required aria-autocomplete="give-name"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-3" :label="$t('Last Name')" label-for="lname" description="">
        <b-form-input id="lname" v-model="credentials.last_name"  placeholder="Nachname" required aria-autocomplete="family-name"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-4" :label="$t('Street / Nr')" label-for="street" description="">
        <b-form-input id="street" v-model="credentials.street"  placeholder="Street" required aria-autocomplete="address-line1"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-6" :label="$t('ZIP-Code')" label-for="zip-code" description="">
        <b-form-input id="zip-code" v-model="credentials.zipcode" type="text" placeholder="ZIP-Code" required aria-autocomplete="postal-code">></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-7" :label="$t('City')" label-for="city" description="">
        <b-form-input id="city" v-model="credentials.city" type="text" placeholder="City" required autocomplete="address-level2">></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-8" :label="$t('Phone')" label-for="phone" description="">
        <b-form-input id="phone" v-model="credentials.phone" type="tel" placeholder="Telefon-Nr." required autocomplete="phone">></b-form-input>
      </b-form-group>
      
      
      <!-- <b-form-checkbox
      id="checkbox-1"
      v-model="agb"
      name="checkbox-1"
      required
      > -->
      <!-- {{$t('I accept the ')}}<router-link to="/terms" > {{$t('Terms and Conditions')}}</router-link> -->
    <!-- </b-form-checkbox> -->
      <div class="mx-2 my-4">
        <b-button type="submit" class="info-button btn btn-outline-dark rounded-0 w-100">Registrieren</b-button>
      </div>
    </b-form>
    <p class="text-danger" v-if="msg">{{ $t(msg) }}</p>
  </div>
</template>
<script>

import AuthService from '@/states/AuthService.js';

export default {
  components: { },
  data() {
    return {
      agb: false,
      address: '',
      credentials: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        street: '',
        zipcode: '',
        city: '',
      },
      msg: ''
    };
  },
  i18n: {
    messages: {
      de: {
          'male': 'männlich',
          'female': 'weiblich',
          'edit': 'bearbeiten',
          'email': 'E-Mail',
          'Enter email': 'E-Mail eingeben',
          'First Name': 'Vorname',
          'Enter name': 'Name eingeben',
          'Last Name': 'Nachname',
          'Street / Nr': 'Strasse / Nr',
          'ZIP-Code': 'PLZ',
          'City': 'Ort',
          'Phone': 'Tel.',
          'Enter last name': 'Nachname eingeben',
          'I accept the ': 'Ich akzeptiere die',
          'Terms and Conditions': 'AGBs'
      },
      fr: {
          'male': 'mâle',
          'female': 'femelle',
          'edit': 'éditer',
          'email': 'e-mail',
          'Enter email': 'Entrez l\'e-mail',
          'First Name': 'Prénom',
          'Enter name': 'Entrez le nom',
          'Last Name': 'Nom de famille',
          'Street / Nr': 'Rue / N°',
          'ZIP-Code': 'Code postal',
          'City': 'Ville',
          'Phone': 'Téléphone',
          'Enter last name': 'Entrer le nom de famille',
          'I accept the ': 'Ich akzeptiere die',
          'Terms and Conditions': 'AGBs'
      }
    }
  },
  computed: {
    ispassword() {
      return this.credentials.password.length >= 8 
    },
    issamepassword() {
      return this.credentials.password === this.credentials.password_confirmation && this.credentials.password != ''
    }
  },
  methods: {
    getAddressData: function(addressData) {
      this.address = addressData;
    },
    async signUp() {
      try {
        var sendc = this.credentials
        if (this.credentials.gender === 'männlich'){
          sendc.gender = 'male'
        }
        if (this.credentials.gender === 'weiblich'){
          sendc.gender = 'female'
        }
        console.log(sendc)
        const response = await AuthService.signUp(sendc);
        this.msg = response.message;
        console.log(this.response);
        this.$emit('sign');
      } catch (error) {
        console.log('error:')
        this.msg = error.response.data.message;
        console.log(this.msg)
      }
    }
  }
};
</script>