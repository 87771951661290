import axios from 'axios';

const url = 'https://'+process.env.VUE_APP_BACKEND;

export default {
  login(credentials) {
    return axios
      .post(url + 'login', credentials)
      .then(response => response.data)
  },
  loaddata(endpoint, auth) {
    return axios
      .get(url + 'be/' + endpoint,{
        headers: {
          'Authorization': auth
        }
      })
      .then(response => response.data)
  },
  getevent(endpoint) {
    return axios
      .get(url + endpoint)
      .then(response => response.data)
  },
  postevent(endpoint,data,auth) {
    return axios
      .post(url + endpoint, data,{
        headers: {
          'Authorization': auth
        }
      })
      .then(response => response.data)
  },
  signUp(credentials) {
    return axios
      .post(url + 'register', credentials)
      .then(response => response.data)
  },
  passwordreset(email){
    return axios
      .post(url + 'forgetpassword',{
        "email": email
      })
      .then(response => response.data)
  },
};